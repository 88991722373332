import { appPaths, externalPaths } from 'utils/path'
import { Color } from 'atomic'
import ColorFunc from 'color'
import { DefaultTheme } from 'styled-components'
import { ClientDoctorSelect } from 'utils/model/client-doctor-select'
import { Menu } from 'utils/model/menu'

export const getClientMenuItems = (theme: DefaultTheme, onContactClick: () => void) =>
  ({
    area: ClientDoctorSelect.client,
    menus: [
      {
        title: 'Institucional',
        items: [
          { title: appPaths.about.name, to: appPaths.about.path.clientUrl },
          { title: appPaths.aboutSerdil.name, to: appPaths.aboutSerdil.path.clientUrl },
          {
            title: appPaths.news.name,
            to: appPaths.news.path.clientUrl,
            external: false
          },
          { title: appPaths.ourSpecialists.name, to: appPaths.ourSpecialists.path.clientUrl }
          // { title: 'Saúde inspira', to: appPaths.about.path.clientUrl }
        ]
      },
      {
        title: externalPaths.weinmannExamResultsClient.name,
        to: externalPaths.weinmannExamResultsClient.path,
        external: true,
        target: '_blank'
      },
      { title: 'Exames e vacinas', to: appPaths.exams.path.clientUrl },
      {
        title: 'Outros serviços',
        to: `${appPaths.otherServices.path.clientUrl}`,
        items: [
          {
            title: 'Exames de imagem',
            to: `${appPaths.otherServices.path.clientUrl}?slug=exames-de-imagem`
          },
          {
            title: 'Genômica',
            to: `${appPaths.otherServices.path.clientUrl}?slug=genomica`
          },
          { title: 'Vacinas', to: `${appPaths.otherServices.path.clientUrl}?slug=vacinas` },
          {
            title: 'Teste para Covid-19',
            to: `${appPaths.otherServices.path.clientUrl}?slug=testes-para-covid-19`
          },
          {
            title: 'Weinmann Kids',
            to: `${appPaths.otherServices.path.clientUrl}?slug=weinmann-kids`
          },
          {
            title: 'Centro da mulher Serdil',
            to: `${appPaths.otherServices.path.clientUrl}?slug=centro-da-mulher-serdil`
          }
        ]
      },
      {
        title: appPaths.convenio.name,
        to: appPaths.convenio.path.clientUrl,
        external: false
      },
      { title: appPaths.units.name, to: appPaths.units.path.clientUrl },
      { title: appPaths.domicileCollect.name, to: appPaths.domicileCollect.path.clientUrl },
      { title: appPaths.contact.name, onClick: onContactClick },
      {
        title: 'Ouvidoria',
        to: 'https://maisservicos.weinmann.com.br/ouvidoria',
        external: true
      }
    ],
    items: [
      {
        title: externalPaths.weinmannExamResultsClient.name,
        to: externalPaths.weinmannExamResultsClient.path,
        external: true,
        target: '_blank'
      },
      { title: 'Exames e vacinas', to: appPaths.exams.path.clientUrl },
      { title: appPaths.convenio.name, to: appPaths.convenio.path.clientUrl },
      { title: appPaths.units.name, to: appPaths.units.path.clientUrl },
      { title: appPaths.domicileCollect.name, to: appPaths.domicileCollect.path.clientUrl },
      { title: appPaths.contact.name, onClick: onContactClick },
      {
        title: 'Ouvidoria',
        to: 'https://maisservicos.weinmann.com.br/ouvidoria',
        external: true
      }
    ],
    otherItems: {
      items: [
        {
          title: appPaths.ourSpecialists.name,
          to: appPaths.ourSpecialists.path.clientUrl,
          external: false
        },
        {
          title: appPaths.news.name,
          to: appPaths.news.path.clientUrl,
          external: false
        }
      ],
      actionColor: ColorFunc(theme.color.accessory)
        .darken(0.27)
        .hex()
    }
  } as Menu)

export const getDoctorMenuItems = (onContactClick: () => void) => {
  const result: Menu = {
    area: ClientDoctorSelect.doctor,
    menus: [
      {
        title: 'Institucional',
        items: [
          { title: appPaths.about.name, to: appPaths.about.path.doctorUrl },
          {
            title: appPaths.news.name,
            to: appPaths.news.path.doctorUrl,
            external: false
          },
          { title: appPaths.ourSpecialists.name, to: appPaths.ourSpecialists.path.doctorUrl }
        ]
      },
      {
        title: externalPaths.weinmannExamResultsDoctor.name,
        to: externalPaths.weinmannExamResultsDoctor.path,
        external: true,
        target: '_blank'
      },
      {
        title: appPaths.medicalMagazines.name,
        to: appPaths.medicalMagazines.path.doctorUrl,
        external: true
      },
      { title: 'Exames e vacinas', to: appPaths.examManual.path.doctorUrl },
      {
        title: 'Outros serviços',
        to: appPaths.otherServices.path.doctorUrl,
        items: [
          {
            title: 'Exames de imagem',
            to: `${appPaths.otherServices.path.doctorUrl}?slug=exames-de-imagem`
          },
          { title: 'Genômica', to: `${appPaths.otherServices.path.doctorUrl}?slug=genomica` },
          { title: 'Vacinas', to: `${appPaths.otherServices.path.doctorUrl}?slug=vacinas` },
          {
            title: 'Teste para Covid-19',
            to: `${appPaths.otherServices.path.doctorUrl}?slug=testes-para-covid-19`
          },
          {
            title: 'Weinmann Kids',
            to: `${appPaths.otherServices.path.doctorUrl}?slug=weinmann-kids`
          },
          {
            title: 'Centro da mulher Serdil',
            to: `${appPaths.otherServices.path.doctorUrl}?slug=centro-da-mulher-serdil`
          }
        ]
      },
      { title: appPaths.units.name, to: appPaths.units.path.doctorUrl },
      {
        title: 'Mais opções',
        items: [
          {
            title: appPaths.convenio.name,
            to: appPaths.convenio.path.doctorUrl,
            external: false
          },
          {
            title: appPaths.doctorRegister.name,
            to: appPaths.doctorRegister.path.doctorUrl,
            external: false
          },
          {
            title: appPaths.areaMedica.name,
            to: appPaths.areaMedica.path.doctorUrl,
            external: false
          }
        ]
      },
      { title: appPaths.contact.name, onClick: onContactClick },
      {
        title: 'Ouvidoria',
        to: 'https://maisservicos.weinmann.com.br/ouvidoria',
        external: true
      }
    ],
    institutional: {
      items: [
        { title: appPaths.about.name, to: appPaths.about.path.doctorUrl },
        {
          title: appPaths.news.name,
          to: appPaths.news.path.doctorUrl,
          external: false
        },
        { title: appPaths.ourSpecialists.name, to: appPaths.ourSpecialists.path.doctorUrl }
      ]
    },
    items: [
      {
        title: externalPaths.weinmannExamResultsDoctor.name,
        to: externalPaths.weinmannExamResultsDoctor.path,
        external: true,
        target: '_blank'
      },
      {
        title: appPaths.medicalMagazines.name,
        to: appPaths.medicalMagazines.path.doctorUrl,
        external: true
      },
      { title: 'Exames e vacinas', to: appPaths.examManual.path.doctorUrl },
      // { title: 'Manual de exames', to: appPaths.examManual.path.doctorUrl },
      // { title: appPaths.ourSpecialists.name, to: appPaths.ourSpecialists.path.doctorUrl },
      { title: appPaths.units.name, to: appPaths.units.path.doctorUrl },
      { title: appPaths.contact.name, onClick: onContactClick },
      {
        title: 'Ouvidoria',
        to: 'https://maisservicos.weinmann.com.br/ouvidoria',
        external: true
      }
    ],
    otherServices: {
      items: [
        { title: 'Exames de imagem', to: appPaths.news },
        { title: 'Genômica', to: appPaths.news },
        { title: 'Vacinas', to: appPaths.news },
        { title: 'Teste para Covid-19', to: appPaths.news },
        { title: 'Weinmann Kids', to: appPaths.news },
        { title: 'Centro da mulher Serdil', to: appPaths.news }
      ]
    },
    otherItems: {
      items: [
        {
          title: appPaths.convenio.name,
          to: appPaths.convenio.path.doctorUrl,
          external: false
        },
        {
          title: appPaths.news.name,
          to: appPaths.news.path.doctorUrl,
          external: false
        },
        {
          title: appPaths.doctorRegister.name,
          to: appPaths.doctorRegister.path.doctorUrl,
          external: false
        },
        {
          title: appPaths.areaMedica.name,
          to: appPaths.areaMedica.path.doctorUrl,
          external: false
        }
      ],
      actionColor: Color.GrayXDark
    }
  }
  return result
}
