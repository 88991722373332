import { appPaths, getPathUrl } from "utils/path";

export function insertOtherLinksOnAbout(
  relatedPages: { title: string; url: string; target?: string }[],
  isDoctorPage: boolean
) {
  relatedPages.push(
    {
      url: 'https://trabalheconosco.vagas.com.br/grupo-fleury',
      //url: 'https://career8.successfactors.com/career?company=FLEURY',
      title: 'Trabalhe Conosco'
    },
    {
      url: getPathUrl(appPaths.faq.path, isDoctorPage),
      title: 'Perguntas Frequentes'
    },
    /* {
        url: 'http://www.grupofleury.com.br/SitePages/Home.aspx',
        title: 'Grupo Fleury'
      },  */
    {
      url: 'https://maisservicos.weinmann.com.br/ouvidoria',
      title: 'Ouvidoria',
      target: '_self'
    }
  )
}
