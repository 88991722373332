import React from 'react'

const SvgIcLogo = props => (
  <svg width="85" height="40" viewBox="0 0 85 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M38.3997 27.8836C37.4992 28.7576 36.4282 29.44 35.2523 29.8893C34.0764 30.3385 32.8204 30.5452 31.5611 30.4963C28.359 30.4963 26.1513 28.5039 24.4033 25.7678C23.5802 27.2613 22.3511 28.4962 20.8556 29.3321C19.3601 30.1679 17.6581 30.5713 15.943 30.4963C7.58796 30.4963 6.00443 16.939 1.71358 11.9589C0.633089 14.3877 0.0517056 17.0056 0.00329465 19.6597C-0.0451163 22.3138 0.440407 24.9508 1.43162 27.4167C2.42284 29.8827 3.89979 32.1281 5.77623 34.0218C7.65267 35.9155 9.89099 37.4195 12.3604 38.446C14.8297 39.4724 17.4807 40.0007 20.1584 40C22.836 39.9993 25.4867 39.4697 27.9555 38.442C30.4244 37.4143 32.6619 35.9091 34.5373 34.0145C36.4128 32.1199 37.8887 29.8737 38.8786 27.4072C38.719 27.5655 38.5593 27.7237 38.3997 27.882"
      fill="white"
    />
    <path
      d="M9.56277 11.4192C13.4067 15.5242 14.1154 26.3818 19.3561 26.3818C20.1778 26.3811 20.985 26.1673 21.6976 25.7617C22.4103 25.3562 23.0036 24.7729 23.4186 24.0698C20.2516 18.1069 18.5036 10.1437 13.9174 10.1437C13.9174 10.1437 22.2373 8.26845 25.1777 11.4097C29.0216 15.5148 29.7303 26.3724 34.9726 26.3724C35.5583 26.3858 36.1401 26.2736 36.6782 26.0437C37.2162 25.8137 37.6979 25.4713 38.0901 25.0398L38.2673 24.8025L38.285 24.774C40.9987 20.9902 36.885 9.58198 30.0481 9.58198C32.4856 9.25951 34.9561 9.26901 37.391 9.61038C35.5944 6.67806 33.0666 4.2537 30.051 2.57052C27.0354 0.887334 23.6336 0.0020704 20.1728 3.62751e-06C16.7121 -0.00206314 13.3091 0.879056 10.2914 2.55864C7.27379 4.23822 4.743 6.65964 2.94287 9.58981C5.36126 9.52809 8.10698 9.86043 9.55322 11.4065"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M73.2068 21.1616L61.5581 30.1378L73.2068 18.5427L84.8541 6.94746H66.3121L65.9311 6.97503L65.5239 7.00252L65.1444 7.05606L64.7647 7.10961L64.3852 7.21821L64.0303 7.32681L63.6508 7.46289L63.2988 7.59898L62.9455 7.75971L62.7826 7.84224L62.5906 7.95075L62.2664 8.14047L61.9377 8.35617L61.6468 8.57187L61.3559 8.81514L61.0549 9.05982L60.783 9.33048L60.5124 9.59981L60.2667 9.89804L60.1315 10.0602L60.0224 10.1949L59.8871 10.3584L59.7781 10.5192L59.5861 10.8449L59.3679 11.1678L59.208 11.4935L59.0451 11.8438L58.8808 12.1956L58.7731 12.5735L58.638 12.9514L58.5565 13.3046L58.4751 13.7086L58.4212 14.0878L58.395 14.4657V33.3055H77.3195L77.7281 33.278L78.1078 33.2244L78.4873 33.1433L78.8683 33.0623L79.2202 32.9551L79.6013 32.8205L79.9532 32.6858L80.3066 32.5237L80.4695 32.4151L80.6323 32.3355L80.9857 32.1458L81.3115 31.9286L81.6023 31.7129L81.9019 31.4697L82.2015 31.199L82.4734 30.9558L82.744 30.6575L82.9898 30.3868L83.125 30.2261L83.234 30.064L83.3693 29.9279L83.4769 29.7657L83.6675 29.4429L83.8826 29.1171L84.047 28.7653L84.2099 28.4149L84.3742 28.0631L84.4803 27.7128L84.5909 27.3349L84.6985 26.9571L84.7785 26.5791L84.8076 26.2013L84.8599 25.7945V22.2533L72.1539 30.145L84.8599 17.4945L66.8473 30.1378L84.8483 12.2173L73.2068 21.1616Z"
      fill="white"
    />
  </svg>
)

export default SvgIcLogo
