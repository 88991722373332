import { getCorporateContactJsonLd } from '@root/src/components/legacy/mol.seo/build-corporate-contact-json-ld'
import { Button, Col, FaIcon, H1, H2, H3, H4, H5, Hbox, Row, Separator } from 'atomic'
import SvgIcMessenger from 'atomic/atm.svg-icon/ic-messenger'
import SvgIcTelefone from 'atomic/atm.svg-icon/ic-telefone'
import SvgIcWhatsapp from 'atomic/atm.svg-icon/ic-whatsapp'
import SvgIcWatch from 'atomic/atm.svg-icon/ic-watch'
import SvgIcCovid from 'atomic/atm.svg-icon/ic-covid'
import SvgIcVacinas from 'atomic/atm.svg-icon/ic-vacinas'
import SvgIcOutrosExames from 'atomic/atm.svg-icon/ic-outros-exames'
import SvgIcCalendarioAgendar from 'atomic/atm.svg-icon/ic-calendario-agendar'
import SvgIcPhoneMobile from 'atomic/atm.svg-icon/ic-phone-mobile'
import SvgIcCheckin from 'atomic/atm.svg-icon/ic-check-in'
import { Modal } from 'atomic/legacy/obj.modal'
import { graphql, StaticQuery } from 'gatsby'
import React, { useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { getTelLink, removeTelAreaCode } from 'utils/number'
import { JsonLd } from '../legacy/mol.seo/json-ld.component'
import {
  SchedulingIconWrapper,
  SchedulingItemWrapper,
  SchedulingModalStyled,
  Wrapper,
  CardContainer,
  SecondModalStyled,
  WrapperFleuryModal,
  AttendanceHours,
  SchedulingButton,
  SchedulingLinkButton,
  LineSeparator,
  ContactsWrapper,
  ContactLabel,
  ContactText,
  ContactTextCustom,
  ContactIcon
} from './scheduling-modal.component.style'
import styled from 'styled-components'

const PhoneLabel = styled.span`
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.1;
  letter-spacing: normal;
  color: #464646;
`

const ExternalLink = styled.a`
  font-family: Signika;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: normal;
  text-decoration: underline;
  color: #047380;
`

const PhoneSeparator = styled.div`
  border: solid 0.5px;
  border-color: #e8e8e8;
  height: 28px;
  margin: auto 10px;
`

const MessageRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ButtonCustom = styled.button`
  display: flex;
  gap: 12px;
  color: #fff;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
  width: 100%;
  padding: 1rem 4rem;
  border: none;
  margin-bottom: 2rem;
  border-radius: 5rem;
  background-color: #047380;
  cursor: pointer;

  &:hover {
    background-color: #046773;
  }
`

interface SchedulingProps {
  open: boolean
  onClose: () => void
}

export const SchedulingModal: React.FunctionComponent<SchedulingProps> = props => {
  const themeContext = useContext(ThemeContext)
  const [openSecondModal, setOpenSecondModal] = useState(false)
  const [openMoreServices, setOpenMoreServices] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleCloseSecondModal = (action?: string) => {
    if (action !== 'back' && openSecondModal) {
      props.onClose()
    }
    setOpenSecondModal(!openSecondModal)
  }

  const handleCloseMoreServices = (action?: string) => {
    if (action !== 'back' && openMoreServices) {
      props.onClose()
    }
    setOpenMoreServices(!openMoreServices)
  }

  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              siteUrl
            }
          }
          cosmicjsInformacoesEstaticas(slug: { eq: "informacoes-de-contato" }) {
            metadata {
              instrucoes
              agendamentoWhatsappLink
              label_whatsapp
              telefoneContato
              telefoneDemaisLocalidades
              chatOnlineLink
              link_messenger_clientes
              agendamentoWhatsappObservacao
              horarioAtendimento
              seo {
                imagem {
                  url
                }
              }
              agende_nome
              agende_link
              agende_texto
            }
          }
        }
      `}
      render={data => {
        const showWhatsappObs =
          data.cosmicjsInformacoesEstaticas.metadata.agendamentoWhatsappObservacao &&
          data.cosmicjsInformacoesEstaticas.metadata.agendamentoWhatsappObservacao.trim()
        let propsContactPhone: {
          title: string
          icon: JSX.Element
          to: string
          title2?: string
          to2?: string
        } = null

        const {
          telefoneContato,
          telefoneDemaisLocalidades
        } = data.cosmicjsInformacoesEstaticas.metadata

        if (telefoneContato) {
          propsContactPhone = {
            title: removeTelAreaCode(telefoneContato),
            icon: <SvgIcTelefone fill={themeContext.color.primary} width={22} />,
            to:
              process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'felippe-mattoso'
                ? ''
                : getTelLink(telefoneContato)
          }
        }
        if (telefoneDemaisLocalidades) {
          propsContactPhone.title2 = telefoneDemaisLocalidades
          propsContactPhone.to2 =
            process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'felippe-mattoso'
              ? ''
              : getTelLink(telefoneDemaisLocalidades)
        }
        return (
          <>
            {process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'weinmann' && (
              <>
                <JsonLd
                  jsonld={getCorporateContactJsonLd({
                    url: data.site.siteMetadata.siteUrl as string,
                    logoUrl: data.cosmicjsInformacoesEstaticas.metadata.seo.imagem.url as string,
                    phone: data.cosmicjsInformacoesEstaticas.metadata.telefoneContato as string
                  })}
                />
                <Modal xSmall onClose={props.onClose} opened={props.open}>
                  <SchedulingModalStyled>
                    <Separator />
                    <Col>
                      <H1>Agendar</H1>
                      <H3>Exames, vacinas e mais</H3>
                    </Col>

                    {data.cosmicjsInformacoesEstaticas.metadata.agende_link && (
                      <>
                        <Wrapper>
                          {/* <CardContainer
                            onClick={() =>
                              window.open(
                                `${data.cosmicjsInformacoesEstaticas.metadata.agende_link}`,
                                '_blank'
                              )
                            }
                          >
                            <SvgIcCovid fill={themeContext.color.primary} width={24} />
                            <H4>COVID-19</H4>
                          </CardContainer> */}
                          <CardContainer
                            onClick={() =>
                              window.open(
                                'https://www.weinmann.com.br/noticias/vacinas-disponiveis-no-weinmann',
                                '_blank'
                              )
                            }
                          >
                            <SvgIcVacinas fill={themeContext.color.primary} width={24} />
                            <H4>Vacinas</H4>
                          </CardContainer>
                          <CardContainer onClick={() => handleCloseSecondModal()}>
                            <SvgIcOutrosExames fill={themeContext.color.primary} width={24} />
                            <H4>Outros exames</H4>
                          </CardContainer>
                        </Wrapper>
                        <Separator />
                        <Separator />
                      </>
                    )}
                    <H3>Tudo pronto para sua visita?</H3>
                    <ButtonCustom
                      onClick={() => {
                        handleCloseMoreServices()
                      }}
                    >
                      <SvgIcCheckin />
                      Fazer check-in
                    </ButtonCustom>
                  </SchedulingModalStyled>
                </Modal>

                <Modal xSmall onClose={handleCloseSecondModal} opened={openSecondModal}>
                  <SecondModalStyled>
                    <Separator />
                    <Col>
                      <H1>Outros exames</H1>
                      <H3>Para agendar seus exames, entre em contato conosco</H3>
                    </Col>
                    <>
                      {propsContactPhone && (
                        <ContactRowFleury
                          title="4004-3080"
                          icon={<SvgIcTelefone fill={themeContext.color.primary} width={22} />}
                          to="tel:40043080"
                          target="_self"
                        />
                      )}

                      {data.cosmicjsInformacoesEstaticas.metadata.agendamentoWhatsappLink && (
                        <ContactRowFleury
                          title={`Whatsapp${showWhatsappObs ? ' *' : ''}`}
                          icon={<SvgIcWhatsapp fill={themeContext.color.primary} width={22} />}
                          to={data.cosmicjsInformacoesEstaticas.metadata.agendamentoWhatsappLink}
                          target="_blank"
                        />
                      )}

                      {data.cosmicjsInformacoesEstaticas.metadata.link_messenger_clientes && (
                        <ContactRowFleury
                          title="Messenger"
                          icon={<SvgIcMessenger fill={themeContext.color.primary} width={24} />}
                          to={data.cosmicjsInformacoesEstaticas.metadata.link_messenger_clientes}
                          target="_blank"
                        />
                      )}

                      <WrapperFleuryModal>
                        {data.cosmicjsInformacoesEstaticas.metadata.horarioAtendimento && (
                          <AttendanceHours
                            dangerouslySetInnerHTML={{
                              __html: data.cosmicjsInformacoesEstaticas.metadata.horarioAtendimento
                            }}
                          />
                        )}
                        <H3>&nbsp;</H3>
                        <Button
                          kind="link"
                          cursor="pointer"
                          onClick={() => {
                            handleCloseSecondModal('back')
                          }}
                        >
                          <FaIcon.ChevronLeft />
                          &nbsp;&nbsp;&nbsp;Voltar
                        </Button>
                      </WrapperFleuryModal>

                      <Separator />
                    </>
                  </SecondModalStyled>
                </Modal>

                <Modal xSmall onClose={handleCloseMoreServices} opened={openMoreServices}>
                  <SecondModalStyled>
                    <Separator />
                    <Col>
                      <H1>Check-in</H1>
                      <H3>
                        Faça o seu check-in digital para <b>exames de sangue, urina e fezes</b> e
                        agilize seu atendimento no dia do exame.
                      </H3>
                      <H3>
                        O check-in deve ser realizado pelo menos <b>24h antes do exame</b>.
                      </H3>
                      <MessageRow>
                        <SvgIcWatch
                          fill={themeContext.color.primary}
                          width={20}
                          style={{ marginTop: -5 }}
                        />
                        <H5>&nbsp;&nbsp;&nbsp;Não disponível para exames de imagem</H5>
                      </MessageRow>

                      <ExternalLink
                        href={`https://checkinac.weinmann.com.br/`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="phones"
                      >
                        Fazer check-in
                      </ExternalLink>
                    </Col>
                    <WrapperFleuryModal style={{ marginTop: 20 }}>
                      <Separator />
                      <Button
                        kind="link"
                        cursor="pointer"
                        onClick={() => {
                          handleCloseMoreServices('back')
                        }}
                      >
                        <FaIcon.ChevronLeft />
                        &nbsp;&nbsp;&nbsp;Voltar
                      </Button>
                    </WrapperFleuryModal>
                    <Separator />
                  </SecondModalStyled>
                </Modal>
              </>
            )}

            {process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'fleury' && (
              <>
                <JsonLd
                  jsonld={getCorporateContactJsonLd({
                    url: data.site.siteMetadata.siteUrl as string,
                    logoUrl: data.cosmicjsInformacoesEstaticas.metadata.seo.imagem.url as string,
                    phone: data.cosmicjsInformacoesEstaticas.metadata.telefoneContato as string
                  })}
                />
                <Modal xSmall onClose={props.onClose} opened={props.open}>
                  <SchedulingModalStyled>
                    <Separator />
                    <Col>
                      <H1 style={{ marginLeft: isMobile ? 0 : '-8px', color: '#302A38' }}>
                        Agendar exames e vacinas
                      </H1>
                      <H4
                        style={{
                          textAlign: 'center',
                          fontSize: '14px',
                          fontWeight: 'normal',
                          marginTop: '16px',
                          marginBottom: '8px'
                        }}
                      >
                        Como prefere agendar?
                      </H4>
                    </Col>

                    {data.cosmicjsInformacoesEstaticas.metadata.agende_link && (
                      <>
                        <SchedulingButton
                          style={{ backgroundColor: '#D31B50' }}
                          onClick={() =>
                            window.open('https://agendamento.fleury.com.br/', '_blank')
                          }
                        >
                          <SvgIcCalendarioAgendar />
                          <ContactTextCustom style={{ marginLeft: '10px' }}>
                            Agendamento Digital
                          </ContactTextCustom>
                        </SchedulingButton>

                        <LineSeparator />

                        {data.cosmicjsInformacoesEstaticas.metadata.agendamentoWhatsappLink && (
                          <SchedulingButton
                            style={{ backgroundColor: '#FFFFFF' }}
                            onClick={() =>
                              window.open(
                                'https://api.whatsapp.com/send?phone=551131790822&text=Ol%C3%A1!%20Quero%20realizar%20um%20agendamento',
                                '_blank'
                              )
                            }
                          >
                            <SvgIcWhatsapp fill={'#D31B50'} width={22} />
                            <ContactText style={{ marginLeft: '10px' }}>WhatsApp</ContactText>
                          </SchedulingButton>
                        )}

                        {propsContactPhone && isMobile ? (
                          <>
                            <SchedulingLinkButton
                              style={{ backgroundColor: '#FFFFFF' }}
                              href="tel:1131790822"
                              target="_blank"
                            >
                              <SvgIcPhoneMobile width={22} />
                              <ContactText style={{ marginLeft: '10px' }}>
                                Grande São Paulo
                              </ContactText>
                            </SchedulingLinkButton>
                            <SchedulingLinkButton
                              style={{ backgroundColor: '#FFFFFF' }}
                              href="tel:08007040822"
                              target="_blank"
                            >
                              <SvgIcPhoneMobile width={22} />
                              <ContactText style={{ marginLeft: '10px' }}>
                                Outras localidades
                              </ContactText>
                            </SchedulingLinkButton>
                          </>
                        ) : (
                          <ContactsWrapper>
                            <ContactIcon style={{ width: 22 }}>
                              {propsContactPhone.icon}
                            </ContactIcon>
                            <div style={{ flexDirection: 'column' }}>
                              <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                                <ContactText>(11) 3179-0822</ContactText>
                                <ContactLabel>Grande São Paulo</ContactLabel>
                              </Row>
                              <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                                <ContactText>0800 704 0822</ContactText>
                                <ContactLabel>Outras localidades</ContactLabel>
                              </Row>
                            </div>
                          </ContactsWrapper>
                        )}
                        <ContactLabel
                          style={{
                            fontSize: '12px',
                            textAlign: 'center',
                            marginTop: '16px',
                            marginBottom: isMobile ? '40px' : '12px',
                            color: '#343838'
                          }}
                        >
                          Todos os dias, das 6h às 22h
                        </ContactLabel>
                        <Separator />
                      </>
                    )}
                  </SchedulingModalStyled>
                </Modal>

                <Modal xSmall onClose={handleCloseSecondModal} opened={openSecondModal}>
                  <SecondModalStyled>
                    <Separator />
                    <Col>
                      <H1>Outros exames</H1>
                      <H3>Para agendar seus exames, entre em contato conosco</H3>
                    </Col>
                    <>
                      {propsContactPhone && <ContactRowPhoneFleury {...propsContactPhone} />}

                      {data.cosmicjsInformacoesEstaticas.metadata.agendamentoWhatsappLink && (
                        <ContactRowFleury
                          title={`Whatsapp${showWhatsappObs ? ' *' : ''}`}
                          icon={<SvgIcWhatsapp fill={themeContext.color.primary} width={22} />}
                          to={data.cosmicjsInformacoesEstaticas.metadata.agendamentoWhatsappLink}
                          target="_blank"
                        />
                      )}

                      {data.cosmicjsInformacoesEstaticas.metadata.link_messenger_clientes && (
                        <ContactRowFleury
                          title="Messenger"
                          icon={
                            <SvgIcMessenger
                              style={{ marginLeft: -3 }}
                              fill={themeContext.color.primary}
                              width={25}
                            />
                          }
                          to={data.cosmicjsInformacoesEstaticas.metadata.link_messenger_clientes}
                          target="_blank"
                        />
                      )}
                      <WrapperFleuryModal>
                        {data.cosmicjsInformacoesEstaticas.metadata.horarioAtendimento && (
                          <AttendanceHours
                            dangerouslySetInnerHTML={{
                              __html: data.cosmicjsInformacoesEstaticas.metadata.horarioAtendimento
                            }}
                          />
                        )}
                        <Separator />
                        <Button
                          kind="link"
                          cursor="pointer"
                          onClick={() => {
                            handleCloseSecondModal('back')
                          }}
                        >
                          <FaIcon.ChevronLeft />
                          &nbsp;&nbsp;&nbsp;Voltar
                        </Button>
                      </WrapperFleuryModal>
                      <Separator />
                    </>
                  </SecondModalStyled>
                </Modal>
              </>
            )}
            {process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'felippe-mattoso' && (
              <>
                <JsonLd
                  jsonld={getCorporateContactJsonLd({
                    url: data.site.siteMetadata.siteUrl as string,
                    logoUrl: data.cosmicjsInformacoesEstaticas.metadata.seo.imagem.url as string,
                    phone: data.cosmicjsInformacoesEstaticas.metadata.telefoneContato as string
                  })}
                />
                {/* <Modal xSmall onClose={props.onClose} opened={props.open}> */}
                <Modal xSmall>
                  <SchedulingModalStyled>
                    <Separator />
                    <Col>
                      <H1>Agendar</H1>
                      <H3>Exames, vacinas e mais</H3>
                    </Col>

                    {data.cosmicjsInformacoesEstaticas.metadata.agende_link && (
                      <>
                        <Wrapper>
                          <CardContainer
                            onClick={() =>
                              window.open(
                                `${data.cosmicjsInformacoesEstaticas.metadata.agende_link}`,
                                '_blank'
                              )
                            }
                          >
                            <SvgIcCovid fill={themeContext.color.primary} width={24} />
                            <H4>COVID-19 e Influenza</H4>
                          </CardContainer>
                          <CardContainer onClick={() => handleCloseSecondModal()}>
                            <SvgIcOutrosExames fill={themeContext.color.primary} width={24} />
                            <H4>Outros exames</H4>
                          </CardContainer>
                        </Wrapper>
                        <Separator />
                        <Separator />
                        <Separator />
                      </>
                    )}
                  </SchedulingModalStyled>
                </Modal>

                {/* <Modal xSmall onClose={handleCloseSecondModal} opened={openSecondModal}> */}
                <Modal xSmall onClose={props.onClose} opened={props.open}>
                  <SecondModalStyled>
                    <Separator />
                    <Col>
                      <H1>Outros exames</H1>
                      <H3>Para agendar seus exames, entre em contato conosco</H3>
                    </Col>
                    {propsContactPhone && (
                      <ContactRowFleury
                        title="(21) 2266 8989"
                        icon={<SvgIcTelefone fill={themeContext.color.primary} width={22} />}
                        to="tel:2122668989"
                        target="_blank"
                      />
                    )}

                    {data.cosmicjsInformacoesEstaticas.metadata.agendamentoWhatsappLink && (
                      <ContactRowFleury
                        title={`WhatsApp (atendimento domiciliar)${showWhatsappObs ? ' *' : ''}`}
                        icon={<SvgIcWhatsapp fill={themeContext.color.primary} width={22} />}
                        to={data.cosmicjsInformacoesEstaticas.metadata.agendamentoWhatsappLink}
                        target="_blank"
                      />
                    )}

                    {data.cosmicjsInformacoesEstaticas.metadata.link_messenger_clientes && (
                      <ContactRowFleury
                        title="Messenger"
                        icon={<SvgIcMessenger fill={themeContext.color.primary} width={25} />}
                        to={data.cosmicjsInformacoesEstaticas.metadata.link_messenger_clientes}
                        target="_blank"
                      />
                    )}
                    {data.cosmicjsInformacoesEstaticas.metadata.horarioAtendimento && (
                      <AttendanceHours
                        dangerouslySetInnerHTML={{
                          __html: data.cosmicjsInformacoesEstaticas.metadata.horarioAtendimento
                        }}
                      />
                    )}
                    <WrapperFleuryModal>
                      <H3>&nbsp;</H3>
                      <Button
                        kind="link"
                        cursor="pointer"
                        onClick={() => {
                          handleCloseSecondModal('back')
                        }}
                      >
                        <FaIcon.ChevronLeft />
                        &nbsp;&nbsp;&nbsp;Voltar
                      </Button>
                    </WrapperFleuryModal>
                    <Separator />
                  </SecondModalStyled>
                </Modal>
              </>
            )}
          </>
        )
      }}
    />
  )
}

interface ContactRowProps {
  title: string
  icon: JSX.Element
  to: string
  target?: '_self' | '_parent' | '_blank' | '_top'
}

const ContactRow: React.FunctionComponent<ContactRowProps> = props => (
  <Row mb>
    <Col xs>
      <Button
        id="button-title-scheduling-modal"
        kind="link"
        to={props.to}
        external
        target={props.target}
      >
        <Hbox>
          <Hbox.Item wrap vAlign="center">
            <SchedulingIconWrapper>{props.icon}</SchedulingIconWrapper>
          </Hbox.Item>
          <Hbox.Separator />
          <Hbox.Item vAlign="center">
            <H4 kind={'default'} cell>
              <SchedulingItemWrapper> {props.title} </SchedulingItemWrapper>
            </H4>
          </Hbox.Item>
        </Hbox>
      </Button>
    </Col>
  </Row>
)

export default ContactRow

const ContactRowFleury: React.FunctionComponent<ContactRowProps> = props => (
  <WrapperFleuryModal>
    <Button
      id="button-title-scheduling-modal"
      kind="link"
      to={props.to}
      external
      target={props.target}
    >
      <Row mb>
        <SchedulingIconWrapper>{props.icon}</SchedulingIconWrapper>
        <SchedulingItemWrapper> {props.title} </SchedulingItemWrapper>
      </Row>
    </Button>
  </WrapperFleuryModal>
)

export { ContactRowFleury }

interface ContactRowPhoneProps {
  title: string
  title2?: string
  icon: JSX.Element
  to: string
  to2?: string
  target?: '_self' | '_parent' | '_blank' | '_top'
}

const ContactRowPhone: React.FunctionComponent<ContactRowPhoneProps> = props => {
  const showSecondPhone = !!props.title2

  return (
    <>
      {process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'weinmann' ? (
        <Row className="NewSpacing" mb>
          <Col xs>
            <Hbox vAlign="center">
              <Button
                id="button-icon-scheduling-modal"
                kind="link"
                to={showSecondPhone ? '' : props.to}
                cursor={showSecondPhone ? 'default' : 'pointer'}
                external
                target={props.target}
              >
                <Hbox.Item wrap>
                  <SchedulingIconWrapper>{props.icon}</SchedulingIconWrapper>
                </Hbox.Item>
              </Button>
              <Button
                id="button-sao-paulo-scheduling"
                kind="link"
                to={props.to}
                external
                target={props.target}
              >
                <Hbox.Item vAlign="center">
                  {showSecondPhone && <PhoneLabel>Grande São Paulo</PhoneLabel>}
                  <H2 className="H2-agendar-exames" kind={'default'} cell>
                    {props.title}
                  </H2>
                </Hbox.Item>
              </Button>
              {showSecondPhone && <PhoneSeparator />}
              {showSecondPhone && (
                <Button
                  id="button-other-location-scheduling-modal"
                  kind="link"
                  to={props.to2}
                  external
                  target={props.target}
                >
                  <Hbox.Item vAlign="center">
                    <PhoneLabel>Outras Localidades</PhoneLabel>
                    <H4 kind={'default'} cell>
                      {props.title2}
                    </H4>
                  </Hbox.Item>
                </Button>
              )}
            </Hbox>
          </Col>
        </Row>
      ) : (
        <Row mb>
          <Col xs>
            <Hbox vAlign="center">
              <Button
                id="button-icon-scheduling-modal"
                kind="link"
                to={showSecondPhone ? '' : props.to}
                cursor={showSecondPhone ? 'default' : 'pointer'}
                external
                target={props.target}
              >
                <Hbox.Item wrap>
                  <SchedulingIconWrapper>{props.icon}</SchedulingIconWrapper>
                </Hbox.Item>
              </Button>
              <Hbox.Separator />
              <Button
                id="button-sao-paulo-scheduling"
                kind="link"
                to={props.to}
                external
                target={props.target}
              >
                <Hbox.Item vAlign="center">
                  {showSecondPhone && (
                    <PhoneLabel>Grande São Paulo e outras localidades</PhoneLabel>
                  )}
                  <H4 kind={'default'} cell>
                    {props.title}
                  </H4>
                </Hbox.Item>
              </Button>
            </Hbox>
          </Col>
        </Row>
      )}
    </>
  )
}

export { ContactRowPhone }

const ContactRowPhoneFleury: React.FunctionComponent<ContactRowPhoneProps> = props => {
  const showSecondPhone = !!props.title2

  return (
    <WrapperFleuryModal>
      <Row>
        <Button
          id="button-icon-scheduling-modal"
          kind="link"
          to={props.to}
          cursor="pointer"
          external
          target={props.target}
        >
          <PhoneLabel style={{ marginLeft: 40 }}>Grande São Paulo</PhoneLabel>
          <Row mb>
            <SchedulingIconWrapper style={{ marginLeft: 10, width: 22 }}>
              {props.icon}
            </SchedulingIconWrapper>
            <SchedulingItemWrapper>(11) 3179-0822</SchedulingItemWrapper>
          </Row>
        </Button>
        {showSecondPhone && (
          <Button
            id="button-other-location-scheduling-modal"
            kind="link"
            to="tel:08007040822"
            external
            target={props.target}
          >
            <PhoneLabel>Outras Localidades</PhoneLabel>
            <SchedulingItemWrapper style={{ marginLeft: 30 }}>0800 704 0822</SchedulingItemWrapper>
          </Button>
        )}
      </Row>
    </WrapperFleuryModal>
  )
}

export { ContactRowPhoneFleury }

const ContactRowPhoneWeinmann: React.FunctionComponent<ContactRowPhoneProps> = props => {
  const showSecondPhone = !!props.title2

  return (
    <WrapperFleuryModal>
      <Row>
        <Button
          id="button-icon-scheduling-modal"
          kind="link"
          //to={props.to}
          to="tel:40043080"
          cursor="pointer"
          external
          target={props.target}
        >
          {/* <PhoneLabel style={{marginLeft: 40}}>Grande São Paulo</PhoneLabel> */}
          <Row mb>
            <SchedulingIconWrapper style={{ marginLeft: 8 }}>{props.icon}</SchedulingIconWrapper>
            <SchedulingItemWrapper>4004-3080 </SchedulingItemWrapper>
          </Row>
        </Button>
        {showSecondPhone && (
          <Button
            id="button-other-location-scheduling-modal"
            kind="link"
            to="tel:0800 704 0822"
            external
            target={props.target}
          >
            <PhoneLabel>Outras Localidades</PhoneLabel>
            <SchedulingItemWrapper style={{ marginLeft: 30 }}>0800 704 0822</SchedulingItemWrapper>
          </Button>
        )}
      </Row>
    </WrapperFleuryModal>
  )
}
export { ContactRowPhoneWeinmann }

const ContactWhatsapp: React.FunctionComponent<ContactRowPhoneProps> = props => {
  const showSecondPhone = !!props.title2

  return (
    <Row mb>
      <Col xs>
        <Hbox vAlign="center">
          <Button
            id="button-sao-paulo-scheduling"
            kind="link"
            to={props.to}
            external
            target={props.target}
          >
            <Hbox.Item vAlign="center">
              {showSecondPhone && <PhoneLabel>Grande São Paulo</PhoneLabel>}
              <H2 className="underline" kind={'default'} cell>
                {props.title}
              </H2>
            </Hbox.Item>
          </Button>
          {showSecondPhone && <PhoneSeparator />}
          {showSecondPhone && (
            <Button
              id="button-other-location-scheduling-modal"
              kind="link"
              to={props.to2}
              external
              target={props.target}
            >
              <Hbox.Item vAlign="center">
                <PhoneLabel>Outras Localidades</PhoneLabel>
                <H4 kind={'default'} cell>
                  {props.title2}
                </H4>
              </Hbox.Item>
            </Button>
          )}
        </Hbox>
      </Col>
    </Row>
  )
}

export { ContactWhatsapp }
