import {
  CosmicjsInformacoesEstaticas,
  CosmicjsInstitucional,
  Query
} from 'site/src/data/graphql/graphql-types'
import { getTelLink, removeTelAreaCode } from 'utils/number'
import { FaIcon } from 'atomic'
import * as React from 'react'
import { FooterContent } from 'utils/model/footer-content'
import { appPaths, getPathUrl } from 'utils/path'
import { getDetailPageUrl } from 'utils/url'
import SvgIcChat from 'atomic/atm.svg-icon/ic-chat'
import SvgIcTelefone from 'atomic/atm.svg-icon/ic-telefone'
import SvgIcMessengerNew from 'atomic/atm.svg-icon/ic-messengerNew'
import SvgIcWhatsapp from 'atomic/atm.svg-icon/ic-whatsapp'
import { insertOtherLinksOnAbout } from '../footer.utils'

export const instagramUrl = 'https://www.instagram.com/laboratorioweinmann/'
export const facebookUrl = 'https://www.facebook.com/LaboratorioWeinmann/'
export const messengerUrl = 'https://www.messenger.com/t/LaboratorioWeinmann'

export const mapToFooterContent = (data, isDoctor: boolean) => {
  const mapToLinkList = (doctor: boolean) => (item: CosmicjsInstitucional) => ({
    title: item.title,
    url: getDetailPageUrl(getPathUrl(appPaths.institutional.path, doctor), item.slug)
  })

  const firstColLinks = data.allCosmicjsInstitucional.nodes.filter(f => process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() === 'weinmann' ? f.slug !== 'sobre' : true).map(mapToLinkList(isDoctor))
  insertOtherLinksOnAbout(firstColLinks, isDoctor)

  const appLinks = (data.appLinks as CosmicjsInformacoesEstaticas).metadata
  const androidUrl: string = appLinks.playStoreLink
  const iosUrl: string = appLinks.appStoreLink

  const contact = (data.contact as CosmicjsInformacoesEstaticas).metadata

/*   const content: FooterContent = {
    section1: {
      title: 'Laboratório Weinmann',
      links: firstColLinks
    },
    section3: {
      title: 'Parceiros e investidores',
      links: [
        {
          url: 'http://ri.fleury.com.br',
          title: 'Investidores'
        }
        // {
        //   url: 'http://suprimentos.fleury.com.br/Paginas/default.aspx',
        //   title: 'Seja um Fornecedor'
        // },
      ]
    }, */
  const content: FooterContent = {
    section1: {
      title: 'WEINMANN E SERDIL',
      links: [
        {
          url: (isDoctor ? '/medico/institucional' : '/institucional'),
          title: 'Institucional'
        },
        {
          url: (isDoctor ? '/medico/noticias' : '/noticias'),
          title: 'Notícias'
        },
        // {
        //   url: '',
        //   title: 'Blog'
        // }
      ]
    },
    /* section3: {
      title: 'Parceiros e investidores',
      links: [
        {
          url: 'http://ri.fleury.com.br',
          title: 'Investidores'
        }
        // {
        //   url: 'http://suprimentos.fleury.com.br/Paginas/default.aspx',
        //   title: 'Seja um Fornecedor'
        // },
      ]
    }, */
    section3: {
      title: 'LINKS ÚTEIS',
      links: firstColLinks
    },

    /* sectionContact: {
      title: 'Canais de contato',
      links: [
        {
          title: removeTelAreaCode(
            isDoctor ? contact.telefoneContatoMedico : contact.telefoneContato
          ),
          icon: <SvgIcTelefone width={20} />,
          url: getTelLink(isDoctor ? contact.telefoneContatoMedico : contact.telefoneContato)
        },
        {
          title: 'Chat-Online',
          icon: <SvgIcChat width={20} />,
          url: isDoctor ? contact.chatOnlineLinkMedico : contact.chatOnlineLink
        },
        {
          title: 'Whatsapp',
          icon: <SvgIcWhatsapp width={20} />,
          url: contact.agendamentoWhatsappLink
        }
      ]
    }, */
    sectionContact: {
      title: 'CONTATO',
      links: [
        {
          // title: removeTelAreaCode(
          //   isDoctor ? contact.telefoneContatoMedico : contact.telefoneContato
          // ),
          title: isDoctor ? contact.telefoneContatoMedico : contact.telefoneContato,
          icon: <SvgIcTelefone width={14} />,
          url: getTelLink(isDoctor ? contact.telefoneContatoMedico : contact.telefoneContato)
        },
        {
          title: 'Chat-Online',
          icon: <SvgIcChat width={20} />,
          url: isDoctor ? contact.chatOnlineLinkMedico : contact.chatOnlineLink
        },
        {
          title: 'Whatsapp',
          icon: <SvgIcWhatsapp width={14} />,
          url: contact.agendamentoWhatsappLink
        },
        {
          title: 'Messenger',
          icon: <SvgIcMessengerNew width={14}/>,
          url: messengerUrl
        }
      ]
    },

    /* sectionMedia: {
      title: 'Mídias sociais',
      links: [
        {
          title: 'Instagram',
          icon: <FaIcon.Instagram size="lg" />,
          url: instagramUrl
        },
        {
          title: 'Facebook',
          icon: <FaIcon.Facebook size="lg" />,
          url: facebookUrl
        },
        {
          title: 'Messenger',
          icon: <FaIcon.Messenger size="lg" />,
          url: messengerUrl
        }
      ]
    }, */
    sectionMedia: {
      title: 'REDES SOCIAIS',
      links: [
        {
          title: 'Instagram',
          icon: <FaIcon.Instagram size="lg" />,
          url: instagramUrl
        },
          {title: 'Facebook',
          icon: <FaIcon.FacebookNew size="lg" />,
          url: facebookUrl},
      ]
    },

    /* sectionApps: {
      androidUrl,
      iosUrl,
      title: 'Aplicativo'
    } */
    sectionApps: {
      androidUrl,
      iosUrl,
      title: 'BAIXE O APP'
    }
  }

  return content
}
