import React from 'react'

const SvgIcMessengerNew = props => (
  <svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M2.65974 16.23V12.9642C0.984584 11.5941 0 9.63626 0 7.52899C0 3.48691 3.59216 0.22998 8 0.22998C12.4078 0.22998 16 3.48691 16 7.52899C16 11.5711 12.4078 14.828 8 14.828C7.2924 14.828 6.59614 14.7437 5.9239 14.5794L2.65974 16.23ZM4.0312 12.3169V14.0331L5.77064 13.1535L6.03337 13.2268C6.66543 13.4032 7.32593 13.4941 8.00003 13.4941C11.6713 13.4941 14.6286 10.8127 14.6286 7.52951C14.6286 4.2463 11.6713 1.56494 8.00003 1.56494C4.32881 1.56494 1.37146 4.2463 1.37146 7.52951C1.37146 9.32235 2.25613 10.9877 3.76368 12.1166L4.0312 12.3169Z"
      fill={props.fill}
    />
    <path d="M3.24207 9.78042L7.24265 5.65771L9.20554 7.56929L12.7104 5.65771L8.75737 9.82668L6.84003 7.82291L3.24207 9.78042Z" fill={props.fill} />
  </svg>
)

export default SvgIcMessengerNew
